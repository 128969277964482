<template>
  <div class="home">
    <section class="start-section">
      <div
        class="video-wrapper"
      >
        <div class="bg-dim" />
        <div
          class="cover-bg"
          :style="`background-image: url(${require('@/assets/frontvideo.webp')})`"
        />
      </div>
      <div class="custom-container">
        <div class="content">
          <p class="starten">
            ein
          </p>
          <h1>EINZIGARTIGES <span class="text-green">PROJEKT</span> </h1>
          <p>für unsere region<br>KITZINGEN UND WÜRZBURG</p>
        </div>
      </div>
    </section>
    <section
      v-if="newsStickerData.length"
      class="newsticker"
    >
      <div
        :class="[
          'next-event-btn-wrapper',
        ]"
      >
        <div class="rainbow">
          <span />
          <span />
        </div>
        <button
          :class="['next-event-btn']"
          @click="$bvModal.show('booking-detail-home-modal')"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <div class="detail">
              <p class="detail-heading">
                +++NEWS<span class="text-tab-orange">TICKER</span>+++
              </p>
              <template v-for="(news, index) in newsStickerData">
                <h5
                  v-if="news.heading"
                  :key="`newStickerTitle${index}`"
                  class="detail-title"
                >
                  {{ news.heading }}
                </h5>
                <p
                  :key="`${news.identification}${index}`"
                  class="detail-info ql-editor"
                  v-html="news.information"
                />
                <div
                  :key="`newStickerImg${index}`"
                  class="image-wrapper"
                >
                  <img
                    v-if="news.image"
                    :src="news.image"
                  >
                </div>
                <hr
                  v-if="index < newsStickerData.length - 1"
                  :key="`newStickerHr${index}`"
                >
              </template>
            </div>
          </transition>
        </button>
      </div>
    </section>
    <section
      v-else
      class="text-center"
    >
      <h5>
        NO Data
      </h5>
      <router-link to="/admin/news">
        GO BACK
      </router-link>
    </section>

    <base-modal
      v-if="newsStickerData.length"
      id="booking-detail-home-modal"
      custom-class="contact-modal"
    >
      <h3 class="title">
        +++NEWS<span class="text-tab-orange">TICKER</span>+++
      </h3>

      <div
        v-for="(news, index) in newsStickerData"
        :key="`newsModal${news.identification}${index}`"
        class="address"
      >
        <h5
          v-if="news.heading"
          class="heading"
        >
          {{ news.heading }}
        </h5>
        <p
          class="information ql-editor"
          v-html="news.information"
        />
        <img
          v-if="news.image"
          :src="news.image"
        >
        <a
          v-if="news.linkUrl"
          class="m-border-btn"
          :href="news.linkUrl"
          target="_blank"
        >
          <h3>
            LINK
          </h3>
        </a>

        <hr v-if="index < newsStickerData.length - 1">
      </div>
    </base-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-news-preview-arena.scss";
</style>

<script>
require('@/plugins/datejs/date-de-DE');
import 'quill/dist/quill.core.css';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      isUpcomingEventLoading: false,
      newsTicker: {},
    };
  },
  computed: {
    ...mapState('admin', {
      newsStickerData: 'previewNews',
    }),
  },
};
</script>
